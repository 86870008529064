import React from "react";
import { isEmpty } from "lodash";

const Dashboard = ({ authData }) => {
  //console.log(authData)
  if (isEmpty(authData)) {
    //window.location.reload(true)
    return null;
  }

  const { user } = authData;

  return (
    <div className="card-body">
      <div className="">
        {!isEmpty(user.firstName) || !isEmpty(user.lastName) ? (
          <p>
            Hello{" "}
            <strong>
              {user.firstName} {user.lastName}!
            </strong>
          </p>
        ) : (
          <p>
            Hello <strong>{user.username}!</strong>
          </p>
        )}
        {user.b2b === "1" ? (
          <p>
            Congratulations you are a{" "}
            <strong className="text-success">B2B client!</strong>
            <br />
            <br />
            <p className="lead">
              {" "}
              You have unlocked new superpowers! Like a 25% overall cart
              discount.
              <br />
              <strong>
                Apply coupon code: <b className="text-success">b2bclient</b>{" "}
                during checkout to get the discount.
              </strong>
            </p>
          </p>
        ) : null}
        <section>
          From your account dashboard you can view your recent orders and see
          your shipping and billing addresses.
        </section>
      </div>
    </div>
  );
};

export default Dashboard;
