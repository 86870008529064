import { gql } from "@apollo/client";

/**
 * Register customer mutation query.
 */
const REGISTER_CUSTOMER = gql`
  mutation RegisterCustomer($input: RegisterCustomerInput!) {
    registerCustomer(input: $input) {
      customer {
        id
        databaseId
        username
        email
        firstName
        lastName
        jwtAuthExpiration
        jwtRefreshToken
        jwtAuthToken
      }
    }
  }
`;

export default REGISTER_CUSTOMER;
