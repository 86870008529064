import React, { useState, useEffect } from "react";
import Link from "gatsby-link";
import Layout from "../../components/layout";
import Login from "../../components/login";
import Register from "../../components/register";
import { isUserLoggedIn, logOut } from "../../utils/functions";
import { isEmpty } from "lodash";
import "./style.scss";
import SEO from "../../components/seo";
import CustomerAccount from "../../components/customer-account";

const MyAccount = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const auth = isUserLoggedIn();
    document.getElementById("lang-switch").href = "/pl/moje-konto/";
    if (!isEmpty(auth)) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [loggedIn]);

  const handleLogout = () => {
    logOut();
    setLoggedIn(false);
  };

  return (
    <>
      <SEO title={"My account"} header={{ siteTitle: " Shroom | Shop" }} />
      <Layout>
        <div style={{ textAlign: "center" }}>
          <h1 className="mt-5 woo-next-cart-heading spec-h">My account</h1>
          <p style={{ maxWidth: "80%", marginLeft: "10%" }}>
            Welcome to the <b>my account</b> page, you can log in to your
            account then manage your orders and account's data & details.
            <br />
            <br />
            {!loggedIn ? (
              <span className="py-5">
                Not a member?{" "}
                <b className="text-success">
                  Register now and get awesome deals!
                </b>
              </span>
            ) : (
              <span className="py-5">
                Got any questions abut your account?{" "}
                <Link className="text-primary" to="/contact/">
                  Contact us
                </Link>
                .
              </span>
            )}
          </p>
        </div>
        {loggedIn ? (
          <div className="account-details container py-5">
            <CustomerAccount
              setLoggedIn={setLoggedIn}
              handleLogout={handleLogout}
            />
          </div>
        ) : (
          <div className="container py-5" style={{ marginTop: "-50px" }}>
            <div className="row" style={{ justifyContent: "center" }}>
              <Login setLoggedIn={setLoggedIn} />
              <Register setLoggedIn={setLoggedIn} />
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default MyAccount;
