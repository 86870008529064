import { gql } from "@apollo/client";

const LOGIN = gql`
  mutation LOGIN($input: LoginInput!) {
    login(input: $input) {
      authToken
      refreshToken
      user {
        id
        databaseId
        username
        name
        email
        firstName
        lastName
        b2b
        jwtAuthExpiration
      }
    }
  }
`;

export default LOGIN;
